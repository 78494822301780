import React from "react"

export default function Ads({ adNo }) {
    
    const _3my1st = <></>
    const _3my2nd = <></>
    const _3my3rd = <></>
    const _3my4th = <></>

    const _1131283 = <></>
    const _1131284 = <></>
    const _1131286 = <></>
    const _1131288 = <></>
    const _1131289 = <></>
    const _1131290 = <></>

    const ads = {
        331: _3my1st,
        332: _3my2nd,
        333: _3my3rd,
        334: _3my4th,
        //
        1131283: _1131283,
        1131284: _1131284,
        1131286: _1131286,
        1131288: _1131288,
        1131289: _1131289,
        1131290: _1131290
    }

    return (
        <>
            { ads[adNo] }
        </>
    )
}
